export class ParamsHandler {
  constructor(controller) {
    this.controller = controller
  }

  handleMutation(entries) {
    entries.forEach(entry => this.updateTurboForm(entry.target))
  }

  updateTurboForm(entry) {
    if (Array.from(entry.querySelectorAll('.composed-word')).length == 0) {
      this.controller.submitTarget.dataset.turbo = true
    } else {
      this.controller.submitTarget.dataset.turbo = this.controller.submitTarget.dataset.nextChapter
    }
  }

  buildParamsFromSentence() {
    const chunksParams = [...this.controller.sentenceTarget.querySelectorAll('.composed-word')].map(
      label => {
        if (label.classList.contains('user-composed')) {
          return { text: label.textContent, type: 'text' }
        } else if (label.classList.contains('linebreak')) {
          return { text: '\n', type: 'linebreak' }
        } else {
          return {
            text: (label.dataset.original || label.textContent).trim(),
            type: 'label',
            original_position: label.dataset.originalPosition
          }
        }
      }
    )
    this.controller.textToSpeechContentTarget.dataset.text = chunksParams
      .map(el => el.text.trim())
      .join(' ')
    this.controller.chunksInputTarget.value = JSON.stringify(chunksParams)
  }
}
