import Shepherd from 'shepherd.js'
import { TemplateInstance } from '@github/template-parts'

export default class Tour {
  /////////////////////////////////////////////
  // Data                                    //
  /////////////////////////////////////////////

  static defaultStepOptions = {
    scrollTo: true,
    classes: 'plume-product-tour'
  }

  /////////////////////////////////////////////
  // Constructor && Public Accessors         //
  /////////////////////////////////////////////

  #slug
  #steps
  #skippedSteps
  #tourFor
  #textToSpeech
  #cta
  #handleCancel
  #handleComplete
  #tour

  constructor({ slug, steps, tourFor, textToSpeech, cta }, handleCancel, handleComplete) {
    this.#slug = slug
    this.#steps = steps
    this.#skippedSteps = []
    this.#tourFor = tourFor || 'kid'
    this.#cta = cta
    this.#textToSpeech = textToSpeech
    this.#handleCancel = handleCancel
    this.#handleComplete = handleComplete
    this.#tour = this.#initializeTour()

    this.#addStepsToTour()
    this.#initializeEvents()
  }

  /////////////////////////////////////////////
  // Public Methods                          //
  /////////////////////////////////////////////

  start() {
    setTimeout(() => {
      this.#tour.start()
    }, 800)
  }

  hide() {
    this.#tour.hide()
  }

  /////////////////////////////////////////////
  // Private Methods and Getters             //
  /////////////////////////////////////////////

  #initializeTour() {
    return new Shepherd.Tour({
      defaultStepOptions: {
        ...Tour.defaultStepOptions,
        classes: `${Tour.defaultStepOptions.classes} ${this.#slug}`
      },
      tourName: this.#slug,
      useModalOverlay: true,
      exitOnEsc: true
    })
  }

  #initializeEvents() {
    const startEvent = new Event('pause:notifications')
    const stopEvent = new Event('resume:notifications')

    Shepherd.on('start', () => {
      document.dispatchEvent(startEvent)
    })
    Shepherd.on('cancel', () => {
      this.#handleCancel(this.#slug)
      document.dispatchEvent(stopEvent)
    })
    Shepherd.on('complete', () => {
      this.#handleComplete(this.#slug)
      document.dispatchEvent(stopEvent)

      // redirect to the CTA link
      const link = this.#tour.currentStep?.options?.cta?.link
      requestAnimationFrame(() => {
        if (link) window.location.href = link
      })
    })
  }

  #addStepsToTour() {
    this.#steps.forEach((step, index) => {
      this.#addStepToTour(step, index)
    })
  }

  #addStepToTour(step, index) {
    this.#tour.addStep({
      ...step,
      when: {
        show: () => {
          this.#moveTitleElement()
          this.#positionElementAndDisplayStepPosition(step, index)
          if (this.#textToSpeech === true || this.#textToSpeech === undefined)
            return this.#addTextToSpeech(step)
        }
      },
      buttons: this.#localizedButtons(index, step.cta),
      showOn: () => Tour.#isElementPresentToAttachStep(step),
      popperOptions: { modifiers: [{ name: 'offset', options: { offset: [0, 30] } }] },
      cancelIcon: { enabled: index === 0 },
      scrollTo: { behavior: 'smooth', block: 'center' }
    })
  }

  // Shepherd doesn't give the possibility to use a custom HTML template
  // so we need to replace the title manually.
  #moveTitleElement() {
    const currentStepElement = this.#tour.currentStep.el
    const image = currentStepElement.querySelector('.product-tour-image')
    const header = currentStepElement.querySelector('.shepherd-header')
    header.insertBefore(image, header.firstChild)
  }

  #positionElementAndDisplayStepPosition(step, index) {
    const currentStepElement = this.#tour.currentStep.el
    if (step.sticky) currentStepElement.classList.add('product-tour-stick-to-bottom')
    if (index === 0 || step.hideStepsCount) return

    const header = currentStepElement.querySelector('.shepherd-header')
    header.insertAdjacentHTML(
      'afterbegin',
      `<span class="steps-count absolute left-5 top-5 text-blue-dark">${index}/${
        this.#stepsCount - 1
      }</span>`
    )
  }

  #addTextToSpeech(step) {
    const currentStepElement = this.#tour.currentStep.el
    const title = currentStepElement.querySelector('.shepherd-title')
    const text = `${step.title}. ${step.text}`.replace(/<[^>]*>/g, '')
    const template = new TemplateInstance(document.querySelector('#tts_component'), {
      text,
      contentClass: 'hidden',
      iconSize: 'lg'
    })

    title.prepend(template)
  }

  #localizedButtons(index, customCta) {
    if (!this.#isMultiStepsTour) return [this.#doneButton(customCta)]
    if (index === 0) return [this.#laterButton(customCta), this.#startButton(customCta)]
    if (index === this.#stepsCount - 1) return [this.#doneButton(customCta)]

    return [this.#backButton(customCta), this.#nextButton(customCta)]
  }

  #doneButton(customCta) {
    return {
      text: customCta?.done || this.#cta.done,
      classes: 'primer--button primary sm',
      action: this.#tour.complete
    }
  }

  #laterButton(customCta) {
    return {
      text: customCta?.later || this.#cta.later,
      classes: 'primer--button ghost sm',
      action: this.#tour.cancel,
      secondary: true
    }
  }

  #startButton(customCta) {
    return {
      text: customCta?.start || this.#cta.start,
      classes: 'primer--button primary sm',
      action: this.#tour.next
    }
  }

  #backButton(customCta) {
    return {
      text: customCta?.back || this.#cta.back,
      classes: 'primer--button ghost sm',
      action: this.#tour.back,
      secondary: true
    }
  }

  #nextButton(customCta) {
    return {
      text: customCta?.next || this.#cta.next,
      classes: 'primer--button primary sm',
      action: this.#tour.next
    }
  }

  get #stepsCount() {
    const hideStepsCountsCount = this.#steps.filter(step => step.hideStepsCount === true).length
    return this.#activeSteps.length - hideStepsCountsCount
  }

  get #isMultiStepsTour() {
    return this.#stepsCount > 1
  }

  get #activeSteps() {
    return this.#steps.filter(step => Tour.#shouldBeDisplayed(step))
  }

  /////////////////////////////////////////////
  // Static Methods                          //
  /////////////////////////////////////////////

  static #isElementPresentToAttachStep(step) {
    return !step.attachTo || !!document.querySelector(step.attachTo.element)
  }

  static #shouldBeDisplayed(step) {
    return (!!step.attachTo && !!document.querySelector(step.attachTo.element)) || !step.attachTo
  }
}
