import { Controller } from '@hotwired/stimulus'
import { DragDrop } from './services/drag_drop'
import { EditableZone } from './services/editable_zone'
import { KeyboardHandler } from './services/keyboard_handler'
import { ParamsHandler } from './services/params_handler'
import { TextTransform } from './services/text_transform'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    'sentence',
    'composable',
    'placeholder',
    'trash',
    'chunksInput',
    'word',
    'textZoneTemplate',
    'lineBreakTemplate',
    'editable',
    'submit',
    'textToSpeechContent'
  ]

  editableClasses = ['shadow', 'border', 'border-gray-lighter', 'bg-white', 'px-4', 'py-3']

  connect() {
    this.editableZone = new EditableZone(this)
    this.textTransform = new TextTransform(this)
    this.dragDrop = new DragDrop(this)
    this.keyboardHandler = new KeyboardHandler(this)
    this.paramsHandler = new ParamsHandler(this)

    this.dragDrop.init()
    this.editableZone.initZones()
    useMutation(this, { childList: true, element: this.sentenceTarget })
    this.paramsHandler.updateTurboForm(this.sentenceTarget)
    this.textTransform.capitalizeStartsOfSentences()
  }

  // actions

  focus(e) {
    if (this.chunksInputTarget.value) return

    this.editableTarget.focus()
  }

  filterKeypress(e) {
    this.keyboardHandler.handleKeypress(e)
  }

  handlePaste(e) {
    this.keyboardHandler.handlePaste(e)
  }

  updateFreeTextZone(e) {
    this.keyboardHandler.updateFreeTextZone(e)
  }

  mutate(entries) {
    this.paramsHandler.handleMutation(entries)
  }
}
