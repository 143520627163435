import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']
  static values = {
    shouldOpen: Boolean,
    shouldReloadOnClose: Boolean,
    shouldRemoveContentOnClose: Boolean,
    shouldDetach: Boolean,
    isDismissable: Boolean
  }

  connect() {
    this.element.controller = this

    if (this.shouldDetachValue && this.element.parentNode !== document.body) {
      document.body.appendChild(this.element)
    }

    if (this.shouldOpenValue) {
      this.show()
    }

    $(this.element).on('hidden.bs.modal', this.onClose.bind(this))
  }

  disconnect() {
    $(this.element).modal('hide')
  }

  show() {
    if (!this.isDismissableValue) {
      $(this.element).modal({ backdrop: 'static', keyboard: false })
    }

    $(this.element).modal('show')
    this.pauseNotifications()
  }

  hide() {
    $(this.element).modal('hide')
    this.resumeNotifications()
  }

  differedShow() {
    setTimeout(this.show.bind(this), 800)
  }

  onClose() {
    this.resumeNotifications()

    $('.modal-backdrop').remove()

    if (this.shouldReloadOnCloseValue) {
      window.location = window.location.pathname
    }

    if (this.shouldRemoveContentOnCloseValue) {
      // very complex to understand what is going on here
      // we should try to either comment this or provide a more idomatic way to do this
      this.contentTarget.firstElementChild.firstElementChild.dataset.dismissed = 'true'
      this.contentTarget.firstElementChild.innerHTML = ''
    }
  }

  reloadPage(e) {
    window.location.reload()
  }

  // private

  pauseNotifications() {
    this.notificationStackController?.pauseNotifications()
  }

  resumeNotifications() {
    this.notificationStackController?.resumeNotifications()
  }

  get notificationStackController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="notification-stack"]'),
      'notification-stack'
    )
  }
}
