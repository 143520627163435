export class KeyboardHandler {
  constructor(controller) {
    this.controller = controller
  }

  handleKeypress(e) {
    if (e.key == 'Enter') e.preventDefault()
    if (this.isEnterKey(e)) {
      this.handleEnterKeyPress(e)
    } else if (this.isBackspaceKey(e)) {
      this.handleBackspaceKeyPress(e)
    } else if (this.isSpaceKey(e)) {
      e.preventDefault()
      this.handleSpaceKeyPress(e)
    } else if (this.isLeftArrowKey(e)) {
      e.preventDefault()
      this.handleLeftArrowPress(e)
    } else if (this.isRightArrowKey(e)) {
      e.preventDefault()
      this.handleRightArrowPress(e)
    } else if (e.key.length !== 1) {
      // Ignore special keys
      return
    } else if (!e.ctrlKey && !e.metaKey && e.currentTarget.classList.contains('editable')) {
      e.currentTarget.classList.add('word', 'composed-word', 'user-composed')
      e.currentTarget.classList.remove('editable')
      e.currentTarget.classList.add(...this.controller.editableClasses)

      this.controller.editableZone.addBefore(e.currentTarget)
      this.controller.editableZone.addAfter(e.currentTarget)
    }
  }

  handlePaste(e) {
    e.preventDefault()
    const pastedText = (e.clipboardData || window.clipboardData).getData('text')

    const lines = pastedText.split(/\n/).filter(line => line.trim().length > 0)

    lines.forEach((line, lineIndex) => {
      const words = line.split(/\s+/).filter(word => word.length > 0)

      words.forEach((word, wordIndex) => {
        const lastEditable = this.controller.sentenceTarget.querySelector('.editable:last-of-type')

        lastEditable.textContent = word
        lastEditable.classList.add('word', 'composed-word', 'user-composed')
        lastEditable.classList.remove('editable')
        lastEditable.classList.add(...this.controller.editableClasses)

        this.controller.editableZone.addBefore(lastEditable)
        this.controller.editableZone.addAfter(lastEditable)

        if (wordIndex === words.length - 1 && lineIndex < lines.length - 1) {
          const editable = this.controller.sentenceTarget.querySelector('.editable:last-of-type')
          editable.after(this.controller.editableZone.lineBreakTemplate)
          const lineBreak = editable.nextElementSibling
          this.controller.editableZone.addAfter(lineBreak)
        }
      })
    })

    const lastEditable = this.controller.sentenceTarget.querySelector('.editable:last-of-type')
    if (lastEditable) {
      lastEditable.focus()
      this.controller.editableZone.setEndOfContenteditable(lastEditable)
    }

    this.controller.paramsHandler.buildParamsFromSentence()
  }

  handleSpaceKeyPress(e) {
    const currentElement = e.currentTarget
    if (currentElement.textContent.length > 0) {
      console.log(currentElement)
      currentElement.classList.add('word', 'composed-word', 'user-composed')
      currentElement.classList.remove('editable')

      this.controller.editableZone.addBefore(currentElement)
      this.controller.editableZone.addAfter(currentElement)

      const nextElement = currentElement.nextElementSibling
      if (nextElement) {
        nextElement.focus()
      }
      this.controller.paramsHandler.buildParamsFromSentence()
    }
  }

  handleEnterKeyPress(e) {
    const currentElement = e.currentTarget
    let newElement

    if (this.isTheCursorPosAtTheEndOfString(currentElement)) {
      newElement = this.controller.editableZone.addNewEditableZoneAfter(currentElement)
    } else {
      const cursorPosition = window.getSelection().getRangeAt(0).startOffset
      newElement = this.controller.editableZone.splitEditableZone(currentElement, cursorPosition)
      if (newElement.textContent) {
        this.updateFreeTextZone({ currentTarget: newElement })
      }
    }
    this.controller.textTransform.capitalizeStartsOfSentences()
    this.controller.paramsHandler.buildParamsFromSentence()
  }

  handleBackspaceKeyPress(e) {
    let previousElement = e.currentTarget.previousElementSibling

    if (e.currentTarget === this.controller.sentenceTarget.querySelector('.editable')) {
      return
    }

    if (previousElement && previousElement.classList.contains('user-composed')) {
      this.controller.editableZone.setEndOfContenteditable(previousElement)
      previousElement.focus()
      e.currentTarget.remove()
    } else if (previousElement && previousElement.classList.contains('composed-word')) {
      const editableBeforeWord = previousElement.previousElementSibling
      if (
        editableBeforeWord &&
        editableBeforeWord.classList.contains('editable') &&
        editableBeforeWord !== this.controller.sentenceTarget.querySelector('.editable')
      ) {
        editableBeforeWord.remove()
      }

      previousElement.classList.remove('composed-word')
      this.controller.composableTarget.appendChild(previousElement)
      e.currentTarget.focus()
      this.controller.editableZone.setEndOfContenteditable(e.currentTarget)
    } else {
      if (e.currentTarget.textContent.length == 0) {
        if (previousElement) {
          previousElement.focus()
          this.controller.editableZone.setEndOfContenteditable(previousElement)
        }
        e.currentTarget.remove()
      }
    }
    this.controller.paramsHandler.buildParamsFromSentence()
  }

  handleLeftArrowPress(e) {
    const currentElement = e.currentTarget
    const previousElement = currentElement.previousElementSibling
    if (previousElement) {
      if (previousElement.classList.contains('editable')) {
        previousElement.focus()
        this.controller.editableZone.setEndOfContenteditable(previousElement)
      } else {
        const previousEditableZone = previousElement.previousElementSibling
        if (previousEditableZone && previousEditableZone.classList.contains('editable')) {
          previousEditableZone.focus()
          this.controller.editableZone.setEndOfContenteditable(previousEditableZone)
        }
      }
    }
  }

  handleRightArrowPress(e) {
    const currentElement = e.currentTarget
    const nextElement = currentElement.nextElementSibling
    if (nextElement) {
      if (nextElement.classList.contains('editable')) {
        nextElement.focus()
        this.controller.editableZone.setEndOfContenteditable(nextElement)
      } else {
        const nextEditableZone = nextElement.nextElementSibling
        if (nextEditableZone && nextEditableZone.classList.contains('editable')) {
          nextEditableZone.focus()
          this.controller.editableZone.setEndOfContenteditable(nextEditableZone)
        }
      }
    }
  }

  // Helpers methods
  isLeftArrowKey(e) {
    return e.key === 'ArrowLeft'
  }

  isRightArrowKey(e) {
    return e.key === 'ArrowRight'
  }

  isSpaceKey(e) {
    return e.key === ' ' || e.code === 'Space'
  }

  isEnterKey(e) {
    return e.key == 'Enter'
  }

  isBackspaceKey(e) {
    return e.key == 'Backspace'
  }

  isTheCursorPosAtTheEndOfString(target) {
    return window.getSelection().getRangeAt(0).startOffset === target.innerText.length
  }

  updateFreeTextZone(e) {
    const freeTextZone = e.currentTarget
    if (freeTextZone.textContent.length > 0) {
      freeTextZone.classList.add('word', 'composed-word', 'user-composed')
      freeTextZone.classList.remove('editable')
      e.currentTarget.classList.add(...this.controller.editableClasses)
      this.controller.editableZone.addAfter(freeTextZone)
      this.controller.editableZone.addBefore(freeTextZone)
    } else {
      freeTextZone.classList.remove('word', 'composed-word', 'user-composed')
      freeTextZone.classList.add('editable')
    }
    this.controller.paramsHandler.buildParamsFromSentence()
  }
}
