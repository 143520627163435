export class TextTransform {
  constructor(controller) {
    this.controller = controller
    this.capitalizeRegex = /^[A-Za-zÀ-ÿ]/
    this.endSentenceRegex = /[.!?]\s*\n*\r*/g
  }

  capitalizeStartsOfSentences() {
    let array = Array.from(this.controller.sentenceTarget.querySelectorAll('.composed-word'))

    array.forEach((element, index) => {
      if (index == 0 || (index > 0 && this.endSentenceRegex.test(array[index - 1].textContent))) {
        this.capitalize(element)
      } else {
        this.uncapitalize(element)
      }
    })
  }

  capitalize(element) {
    if (!element) return

    element =
      element.querySelector('.raw_content') ||
      element.querySelector('[data-primer--text-to-speech--component-target="content"]') ||
      element.querySelector('[data-primer--text-to-speech--component-target="icon content"]') ||
      element
    element.dataset.original ||= element.textContent
    element.textContent = element.textContent
      .trimStart()
      .replace(this.capitalizeRegex, c => c.toUpperCase())
  }

  uncapitalize(element) {
    if (!element) return

    element =
      element.querySelector('.raw_content') ||
      element.querySelector('[data-primer--text-to-speech--component-target="content"]') ||
      element.querySelector('[data-primer--text-to-speech--component-target="icon content"]') ||
      element
    if (element.dataset.original) {
      element.textContent = element.dataset.original
    }
  }
}
