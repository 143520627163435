import axios from 'axios'
import { getMetaValue } from '../utils'

axios.defaults.withCredentials = true

export const notificationUnread = async kid_id => {
  return axios.get('/api/v1/notifications/unread', {
    params: params(kid_id),
    withCredentials: true
  })
}

const params = kid_id => (kid_id ? { kid_id: kid_id } : {})

export const createReview = async data => {
  return axios({
    method: 'post',
    url: '/api/v1/reviews',
    data
  })
}

export const createCorrectionRequest = async data => {
  return axios({
    method: 'post',
    url: '/api/v1/correction_requests',
    data
  })
}

export const immersiveReaderToken = async () => {
  return axios({
    method: 'post',
    url: '/api/v1/immersive_readers'
  }).then(response => response.data.access_token)
}

export const notificationMarkAsRead = async kid_id => {
  return axios.put('/api/v1/notifications/unread', params(kid_id))
}

export const notificationMarkAsReadWithId = async (notification_id, notified_id, notified_type) => {
  return axios.put('/api/v1/notifications/unread', {
    notification_id: notification_id,
    notified_id: notified_id,
    notified_type: notified_type
  })
}

// legacy for ios app 1.2.1
export const createAppleSubscription = async data => {
  return axios({
    method: 'post',
    url: '/api/v1/subscriptions/apple',
    data
  })
}

// legacy for ios app 1.2.1
export const getNewToken = async () => {
  return axios({
    method: 'post',
    url: '/api/v1/users/token'
  })
}

export const updateZone = async (id, data) => {
  return axios({
    method: 'put',
    accept: 'application/json',
    url: '/api/v1/zones/' + id,
    data
  })
}

const instance = axios.create({
  baseURL: '/',
  headers: {
    'X-CSRF-Token': getMetaValue('csrf-token')
  },
  accept: 'application/json'
  // withCredentials: true
})

function get(url, params = {}) {
  return instance.get(url, params)
}

function patch(url, params = {}) {
  return instance.patch(url, params)
}

function post(url, params = {}) {
  return axios({
    method: 'post',
    url: url,
    headers: {
      accept: 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': getMetaValue('csrf-token')
    },
    params
  })
  // return instance.post(url, params)
}

export { get, patch, post }
