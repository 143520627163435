import Controller from './application_controller'
import Tour from '../models/tour'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = { selectedTour: Object, enabled: Boolean }

  connect() {
    if (!this.hasEnabledValue) return
    if (!this.hasSelectedTourValue) return
    if (Object.keys(this.selectedTourValue).length === 0) return

    this.tour = new Tour(
      this.selectedTourValue,
      this.handleCancel.bind(this),
      this.handleComplete.bind(this)
    )
    this.tour.start()
  }

  disconnect() {
    this.tour?.hide()
  }

  // Callbacks

  handleCancel(slug) {
    post('/api/v1/completed_tours', { body: this.#productTourData({ slug, cancel: true }) })
  }

  handleComplete(slug) {
    post('/api/v1/completed_tours', { body: this.#productTourData({ slug }) }).then(() => {
      document.documentElement.setAttribute('completed-tour', slug) // This is useful for integration tests
    })
  }

  // Private

  #productTourData({ slug, cancel = false }) {
    if (!this.#user) return {}

    return {
      completed_tour: {
        ...this.#user,
        slug,
        cancel
      }
    }
  }

  get #user() {
    const { tourFor } = this.selectedTourValue

    if (tourFor === 'kid') return { kid_id: this.kidId }
    if (tourFor === 'user') return { user_id: this.userId }

    return null
  }
}
